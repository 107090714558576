<template>
    <div>
        <v-container>
            <v-row
                dense
            >
                <v-col
                    cols="12"
                >
                    <v-card
                        :loading="loadingData"
                    >
                        <v-card-title>
                            Ordini di {{ loggedUserCompany.name }}
                        </v-card-title>
                        <v-card-text>
                            <template>
                                <v-data-table
                                    :headers="orderHeaders"
                                    :items="orderList"
                                    :footer-props="{
                                    itemsPerPageText: 'Righe per pagina:',
                                    itemsPerPageAllText: 'Tutte',
                                    itemsText: 'righe',
                                    ofText: 'di',
                                    pageText: 'Pagina',
                                    prevText: 'Precedente',
                                    nextText: 'Successiva',
                                    rowsPerPageText: 'Righe per pagina',
                                    noResultsText: 'Nessun risultato',
                                    rowsText: 'righe'
                                }"
                                />
                            </template>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "orders",
    components: {},
    computed: {
        ...mapGetters(['loggedUser','orderList','loggedUserCompany'])
    },
    data() {
        return {
            loadingData: true,
            breadCrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                },
                {
                    text: 'Ordini',
                    to: '/orders',
                }
            ],
            orderHeaders: [
                {
                    text: 'Data',
                    align: 'left',
                    sortable: true,
                    value: 'createdAtFormatted',
                },
                {
                    text: 'Effettuato da',
                    align: 'left',
                    sortable: false,
                    value: 'user.fullName',
                },
                {
                    text: 'Importo',
                    align: 'left',
                    sortable: true,
                    value: 'products_sum_price',
                },
                {
                    text: 'Stato',
                    align: 'right',
                    sortable: false,
                    value: 'statusText',
                }
            ]
        }
    },
    async mounted() {
        await this.$store.dispatch('setBreadCrumbLevels',this.breadCrumb)
        if(this.orderList.length === 0){
            await this.$store.dispatch('getOrders')
        }

        this.loadingData = false
    },
    methods: {

    },
}
</script>