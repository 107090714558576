import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"loading":_vm.loadingData}},[_c(VCardTitle,[_vm._v(" Ordini di "+_vm._s(_vm.loggedUserCompany.name)+" ")]),_c(VCardText,[[_c(VDataTable,{attrs:{"headers":_vm.orderHeaders,"items":_vm.orderList,"footer-props":{
                                itemsPerPageText: 'Righe per pagina:',
                                itemsPerPageAllText: 'Tutte',
                                itemsText: 'righe',
                                ofText: 'di',
                                pageText: 'Pagina',
                                prevText: 'Precedente',
                                nextText: 'Successiva',
                                rowsPerPageText: 'Righe per pagina',
                                noResultsText: 'Nessun risultato',
                                rowsText: 'righe'
                            }}})]],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }